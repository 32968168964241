@font-face {
  font-family: "Bold";
  src: url("https://storage.googleapis.com/download/storage/v1/b/router-panel.appspot.com/o/assets%2Fbold.woff?alt=media")
    format("woff");
}

@font-face {
  font-family: "Regular";
  src: url("https://storage.googleapis.com/download/storage/v1/b/router-panel.appspot.com/o/assets%2Fregular.woff?alt=media")
    format("woff");
}

@font-face {
  font-family: "SemiBold";
  src: url("https://storage.googleapis.com/download/storage/v1/b/router-panel.appspot.com/o/assets%2FsemiBold.woff?alt=media")
    format("woff");
}

* {
  font-family: "Segoe UI", "Roboto";
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating-icon {
  animation: rotate 0.5s ease-in-out infinite; /* Adjust speed and timing */
}

/* 
@font-face {
  font-family: "Bold";
  src: local("Bold"), url(./fonts/Bold.woff) format("woff");
}

@font-face {
  font-family: "Regular";
  src: local("Regular"), url(./fonts/Regular.woff) format("woff");
}

@font-face {
  font-family: "SemiBold";
  src: local("SemiBold"), url(./fonts/SemiBold.woff) format("woff");
}

* {
  font-family: "SemiBold" !important;
}

 */
